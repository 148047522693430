var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"align":"center"}},[_c('h1',{staticClass:"section-title",style:({
            opacity: _vm.changeOpacity,
            backgroundImage: ("linear-gradient(" + _vm.secondColorEffect + " , " + _vm.primaryColorEffect + " " + _vm.colorFraction + "%)"),
            fontFamily: 'Montserrat, sans-serif',
            overflowX: 'hidden',
            webkitBackgroundClip: 'text',
            color: 'transparent',
        })},[_vm._v(" "+_vm._s(_vm.title)+" ")])])}
var staticRenderFns = []

export { render, staticRenderFns }